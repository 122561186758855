import './UserOrdersPage.scss';
import { collection, doc, DocumentData, getDoc, getDocs, orderBy, query, QuerySnapshot, startAt, Timestamp, updateDoc, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { db } from "../../utils/firebaseSetup";
import { AuthContext } from "../../context/AuthContext";
import { OrderData } from "../../models/OrderData.model";
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import OrderItem from '../../components/OrderItem/OrderItem';


export default function UserOrdersPage(){

    const {userId} = useParams();
    const navigate = useNavigate();

    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const [loading, setLoading] = useState(true);

    const [statusList, setStatusList]  = useState<string[]>([]);


    
    function refreshStatusList(){
        getDocs(query(collection(db, 'statusList'), orderBy('timeStamp', 'asc'))).then((q:QuerySnapshot)=>{
            let statuses:string[] = [];
            q.forEach(d=>statuses.push(d.id));
            setStatusList(statuses);
        })
    }


    useEffect(()=>{
        if(!userId){
            navigate('/');
        }else{
            const docRef = doc(db, "users", `${userId}`);
            getDoc(docRef).then((docSnap)=>{
                if (!docSnap.exists()) {
                    navigate('/');
                }else{
                    setUserPhoneNumber(docSnap.data().phoneNumber);
                }
            }, console.error);

            refreshStatusList();
        }
    }, [userId])


    const [orders, setOrders] = useState<OrderData[]>([]);

    const user = useContext(AuthContext);

    const options:Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit' };

    useEffect(() => {

        if(!user){
            return;
        }

        const todayDate = new Date();
        todayDate.setMonth(todayDate.getMonth() - 3);

        const q = query(collection(db, "orders"), where('uid', '==', userId), where("timeStamp", ">", Timestamp.fromDate(todayDate)), orderBy('timeStamp', 'desc'));

        getDocs(q).then((querySnapShot)=>{
            setOrders(querySnapShot.docs.map(doc=>doc.data() as OrderData));
            setLoading(false);
        }, (err)=>alert(err))     
        
    }, [])

    function changeStatus(val:string, orderId:string, index:number){
        if(statusList.includes(val)){

            updateDoc(doc(db, 'orders', `${orderId}`), {
                status:val
            }).then((res)=>{
                setOrders(ord=>{
                    ord[index].status = val;
                    return [
                        ...ord
                    ]
                   })
                toast.success(`status changed to ${val}`);
            }, console.error);
        }
    }

    function orderDeleted(index:number){
        setOrders(ordlist=>{
            ordlist.splice(index, 1);
            return [...ordlist]
        })
    }


    return (
    <div className="user-orders-page page">
        <div className="section-title text-center">
            <h3>User Orders ({userPhoneNumber})</h3>
        </div>
        {loading?<div className="loading-wrap">
            <div className="center-vertical-horizontal">
                <Spinner animation="border" variant="primary" />
            </div>
        </div>:orders?.length>0?<div className="orders-list-wrap">
            <ul className="orders-list list-unstyled">
                {orders.map((order:OrderData, index:number)=>{
                    return (<OrderItem order={order} index={index} options={options} changeStatus={changeStatus} statusList={statusList} onDelete={orderDeleted}/>)
                })}
            </ul>
        </div>:<div className="empty-list-wrap">
        <div className="center-vertical-horizontal">
             <h3>Looks like there are no recent Orders</h3>
        </div>
    </div>}
    </div>)
}