import { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { User } from "firebase/auth";
import { auth } from "../utils/firebaseSetup";

export default function AuthProvider({ children }:any){
  const [user, setUser] = useState<User | null>(getUser());

  function getUser(){
    let usrstr = localStorage.getItem('adminuser');
    if(usrstr){
      try{
        let usrjson = JSON.parse(usrstr);
        if(usrjson){
          return (usrjson as User)
        }
      }
      catch(e){
        return null
      }
    }
    return null
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {

          if(user && user.uid===firebaseUser?.uid){
            return;
          }

          setUser(firebaseUser);
          localStorage.setItem('adminuser', JSON.stringify(firebaseUser));
      });

    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};