import './AdminUsersList.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { collection, deleteDoc, doc, DocumentReference, getDocs, orderBy, query, QuerySnapshot, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../../utils/firebaseSetup';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';

interface AdminUser {
  phoneNumber: string;
}

function AdminUsersList({  }: {  }) {


    const [adminUsers, setAdminUsers]  = useState<string[]>();
    const [newNumber, setNewNumber] = useState('');

    const user = useContext(AuthContext);

    useEffect(()=>{
        refreshList();
    }, [])

    function refreshList(){
        getDocs(query(collection(db, 'adminList'), orderBy('timeStamp', 'desc'))).then((q:QuerySnapshot)=>{
            let numbers:string[] = [];
            q.forEach(d=>numbers.push(d.id));
            setAdminUsers(numbers);
        })
    }
   

    const handleAddButton = async () => {
        if(!user){
            return;
        }
       if(newNumber.length!=10){
        toast.error('Phone Number should be 10 digits length');
        return;
       }
        let docRef:DocumentReference = doc(db, 'adminList', `+91${newNumber}`);
            setDoc(docRef, {
                createdBy: user.phoneNumber,
                timeStamp: serverTimestamp()
            }).then((val)=>{
                refreshList();
                toast.success(`Phone Number +91${newNumber} added to admins Successfully`);
                setNewNumber('');
            }, (error)=>toast.error(error.toString()));
            

    };


    const onPhoneNumberChange = (e:any) =>{
        const re = /^\d{1,10}$/;
    
        if (e.target.value === '' || re.test(e.target.value)) {
            setNewNumber(e.target.value)
        }
    }

    function deleteTheDoc(mobileNumber:string){

        if(!window.confirm(`Are you sure want to delete ${mobileNumber} from Admins List ?`)){
            return;
        }


        let docRef:DocumentReference = doc(db, 'adminList', `${mobileNumber}`);
        deleteDoc(docRef).then((val)=>{
            refreshList();
            toast.success(`Phone Number ${mobileNumber} deleted from admins Successfully`);
            setNewNumber('');
        }, (error)=>toast.error(error.toString()));
    }

    return (
        <div className='admin-users-list'>
            <div className='heading'>
                <p>Admin Users</p>
            </div>
            {/* 
            <div className='user-list-wrap'>

            </div>
            <div className='add-new-wrap'>
                <div className='mobile-number-wrap'>
                    <input type="tel" name="phonenumber" className="form-control" placeholder="Enter phone number" id="name" required data-validation-required-message="Please enter your Phone numnber."
                        value={newNumber} 
                        pattern="[0-9]{10}"
                        onChange={onPhoneNumberChange}
                    />
                </div>
                <div className='add-btn-wrap'>
                        <Button >Add</Button>
                </div>
            </div> */}
            <div className='user-list-wrap'>
                <ul style={{listStyle:'none'}}>
                    {adminUsers?null:'Loading...'}
                    {adminUsers?.map((user: string) => (
                        <li key={user}><div className='mobile-number-wrap'>
                                <span className='mobile-number'>{user}</span>
                            </div>
                            <Button onClick={()=>deleteTheDoc(user)} className="delete-btn" variant="outline-danger">X</Button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='add-new-wrap'>
                <div className='mobile-number-wrap'>
                    <input type="tel" name="phonenumber" className="form-control" placeholder="Enter phone number" id="name" required data-validation-required-message="Please enter your Phone numnber."
                        value={newNumber} 
                        pattern="[0-9]{10}"
                        onChange={onPhoneNumberChange}
                    />
                </div>
                <div className='add-btn-wrap'>
                        <Button onClick={handleAddButton}>Add</Button>
                </div>
            </div>
        </div>
    );
}

export default AdminUsersList;
