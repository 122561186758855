export function getLast3NumericValue(str:string){
    let res='';
    for(let i=str.length-3; i<str.length; i++){
        let ch = str.charCodeAt(i);
        if(ch>=48 && ch<=57){
            res+=((ch-48<=9)?'0':'')+(ch-48);
        }else if(ch>=65 && ch<=90){
            res+=(ch-65)+10;
        }else if(ch>=97 && ch<=122){
            res+=(ch-97)+36;
        }
    }
    return res
}