import { useState } from 'react';
import { Button } from 'react-bootstrap';
import AdminUsersList from '../../components/AdminUsersList/AdminUsersList';
import OrderStatusEditList from '../../components/OrderStatusEditList/OrderStatusEditList';
import './SettingsPage.scss';

type SettingsPageProps = {

}

export default function SettingsPage(props:SettingsPageProps){

    return (<div className='settings-page page'>
        <div className='admin-list-status-list'>
            <div className='admin-users'>
                <AdminUsersList />
            </div>
            <div className='statuses-wrap'>
                <OrderStatusEditList />
            </div>
        </div>
    </div>)
}