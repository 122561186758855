import { signInWithPhoneNumber, RecaptchaVerifier, ConfirmationResult } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { openSync } from "fs";
import { useContext, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import { auth, db } from "../../utils/firebaseSetup";
import "./SignInPage.scss";

export default function SignInPage(){
    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState('');
    const [sentOTP, setSentOTP] = useState(false);
    const [final, setfinal] = useState<ConfirmationResult>();
    const [signingIn, setSigningIn] = useState(false);

    const [resendTimeoutCounter, setResendTimeoutCounter] = useState(0);

    const recaptchaDivRef = useRef<HTMLDivElement>(null);

    const user = useContext(AuthContext);

    const [searchParams] = useSearchParams();
    const navTo = searchParams.get("redirect_to");
    const navigate = useNavigate();

    useEffect(() => {
        resendTimeoutCounter > 0 && setTimeout(() => setResendTimeoutCounter(resendTimeoutCounter - 1), 1000);
      }, [resendTimeoutCounter]);

    useEffect(()=>{
        if(user){
            if(navTo){
                navigate(navTo);
            }else if(window.location.pathname==='/sign-in'){
                navigate('/');
            }else{
                window.location.reload();
            }
        }
    }, [user, navTo, navigate])


    const signin = () => {
        if (mynumber === "" || mynumber.length < 10) return;
        setSentOTP(false);
        setSigningIn(true);

        let verify = new RecaptchaVerifier('recaptcha-container',  {}, auth);

        signInWithPhoneNumber(auth, "+91"+mynumber, verify).then((result) => {
            setfinal(result);
            setSentOTP(true);
            if(recaptchaDivRef)recaptchaDivRef.current?.removeChild(recaptchaDivRef.current.children[0]);
            setResendTimeoutCounter(61);
            
        }).catch((err) => {
            alert('Please try after some time');
        }).finally(()=>{
            setSigningIn(false);
            setSentOTP(true);
        })
    }

   
     const ValidateOtp = () => {
        if (otp === null || final === null || final === undefined || otp==='' || otp.length!==6)
            return;
            setSigningIn(true);
        final.confirm(otp).then((res) => {
            
            const user = res.user;
            const docRef = doc(db, "users", `${user.uid}`);
            getDoc(docRef).then((docSnap)=>{
                if (!docSnap.exists()) {
                    setDoc(doc(db, "users", `${user.uid}`), {
                        uid: user.uid,
                        authProvider: "phone",
                        phoneNumber: user.phoneNumber,
                        timeStamp: serverTimestamp()
                    }).then(console.log, console.error)
                }
            }, console.error).finally(()=>{
                toast.success('Login Success');
            })

        }).catch((err)=>alert(err)).finally(()=>{setSigningIn(false);})
    }

    const onPhoneNumberChange = (e:any) =>{
        const re = /^\d{1,10}$/;
    
        if (e.target.value === '' || re.test(e.target.value)) {
            setnumber(e.target.value)
        }
    }

    const onOtpChange = (e:any) =>{
        const re = /^\d{1,6}$/;
    
        if (e.target.value === '' || re.test(e.target.value)) {
            setotp(e.target.value)
        }
    }
    
    return (

        <div className="sign-in-page page">
            <center>
                <form name="signInForm" id="signInForm" onSubmit={(e:any)=>e.preventDefault()}>
                    <div className="sign-in-container">
                        <h3 className="header">Sign In</h3>
                        <div className="form-group">
                            <input type="tel" name="phonenumber" className="form-control" placeholder="Enter your phone number" id="name" required data-validation-required-message="Please enter your Phone numnber."
                                value={mynumber} 
                                pattern="[0-9]{10}"
                                onChange={onPhoneNumberChange}
                                readOnly={sentOTP}
                            />
                            <br />
                        </div>
                        <div className="form-group" style={{ display: sentOTP ? "block" : "none" }}>
                            <input type="tel" 
                                value={otp}
                                name="otpinput" 
                                className="form-control" 
                                placeholder={"Enter your OTP"}
                                required data-validation-required-message="Please enter the OTP from SMS received on your Phone."
                                onChange={onOtpChange} />
                            <br />
                        </div>
                        <div id="recaptcha-container" ref={recaptchaDivRef}></div>
                        
                        {signingIn?<Spinner animation="border" variant="primary" />:sentOTP?<><button className="btn btn-primary" type="submit" onClick={ValidateOtp}>Verify OTP</button>
                            <button className="btn btn-primary" disabled={resendTimeoutCounter>0}  onClick={signin}>ReSend OTP{resendTimeoutCounter>0?` (wait ${resendTimeoutCounter} seconds)`:''}</button></>:
                            <button className="btn btn-primary" type="submit" onClick={signin}>Continue</button>}
                    </div>
                </form>
                {/* <div style={{ display: !show ? "block" : "none" }}>
                    <input value={mynumber} onChange={(e) => { 
                       setnumber(e.target.value) }}
                        placeholder="phone number" />
                    <br /><br />
                    <div id="recaptcha-container"></div>
                    <button onClick={signin}>Send OTP</button>
                </div>
                <div style={{ display: show ? "block" : "none" }}>
                    <input type="text" placeholder={"Enter your OTP"}
                        onChange={(e) => { setotp(e.target.value) }}></input>
                    <br /><br />
                    <button onClick={ValidateOtp}>Verify</button>
                </div> */}
            </center>
        </div>
    )
}