
import "./OrdersPage.scss";
import { collection, doc, DocumentData, getDoc, getDocs, orderBy, query, QuerySnapshot, startAt, Timestamp, updateDoc, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { db } from "../../utils/firebaseSetup";
import { AuthContext } from "../../context/AuthContext";
import { OrderData } from "../../models/OrderData.model";
import { toast } from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Spinner } from "react-bootstrap";
import OrderItem from "../../components/OrderItem/OrderItem";
import { getLast3NumericValue } from "../../utils/orderIdUtil";


const statusList:string[] =[
    'PREPARING',
    'PICKED UP',
    'PACKING UP',
    'DELIVERED'
]

export default function OrdersPage(){

    const [orders, setOrders] = useState<OrderData[]>([]);
    const [displayOrders, setDisplayOrders] = useState<OrderData[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchInp, setSearchInp] = useState('');
    const user = useContext(AuthContext);

    const options:Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit' };

    const [statusList, setStatusList]  = useState<string[]>([]);


    
    function refreshStatusList(){
        getDocs(query(collection(db, 'statusList'), orderBy('timeStamp', 'asc'))).then((q:QuerySnapshot)=>{
            let statuses:string[] = [];
            q.forEach(d=>statuses.push(d.id));
            setStatusList(statuses);
        })
    }

    useEffect(() => {

        if(!user){
            return;
        }

        const todayDate = new Date();
        todayDate.setMonth(todayDate.getMonth() - 3);

        const q = query(collection(db, "orders"), where("timeStamp", ">", Timestamp.fromDate(todayDate)), orderBy('timeStamp', 'desc'));

        getDocs(q).then((querySnapShot)=>{
            setOrders(querySnapShot.docs.map(doc=>doc.data() as OrderData));
            setLoading(false);
        }, (err)=>alert(err))      

        refreshStatusList();
        
    }, [])

    function changeStatus(val:string, orderId:string, index:number){
        if(statusList.includes(val)){

            updateDoc(doc(db, 'orders', `${orderId}`), {
                status:val
            }).then((res)=>{
                setOrders(ord=>{
                    ord[index].status = val;
                    return [
                        ...ord
                    ]
                   })
                toast.success(`status changed to ${val}`);
            }, console.error);
        }
    }

    function searchChange(e:any){
        setSearchInp(e.target.value);
    }

    useEffect(()=>{

        setDisplayOrders(orders.filter((order:OrderData)=>{
            return (order.timeStamp.seconds.toString().substring(0, 6)+'-'+order.timeStamp.seconds.toString().substring(6)+'-'+getLast3NumericValue(order.orderId)).includes(searchInp) ||
            order.pickupPhoneNumber.includes(searchInp) || order.userPhoneNumber.includes(searchInp) || 
            order.name.toLowerCase().includes(searchInp.toLowerCase()) || order.address.toLowerCase().includes(searchInp.toLowerCase());
        }))

    }, [searchInp, orders])

    function orderDeleted(index:number){
        setOrders(ordlist=>{
            ordlist.splice(index, 1);
            return [...ordlist]
        })
    }

    return (
    <div className="orders-page page">
        <div className="section-title text-center">
            <h3>All Orders</h3>
           <div className="search-wrap">
            <input 
                    className="order-search form-control"
                    placeholder="Search Order"
                    title="Search Order"
                    onChange={(e)=>searchChange(e)}
            />
           </div>
        </div>
        {loading?<div className="loading-wrap">
            <div className="center-vertical-horizontal">
                <Spinner animation="border" variant="primary" />
            </div>
        </div>:orders?.length>0?<div className="orders-list-wrap">
            <ul className="orders-list list-unstyled">
                {displayOrders.map((order:OrderData, index:number)=>{
                    return (<OrderItem order={order} index={index} changeStatus={changeStatus} statusList={statusList} options={options} onDelete={orderDeleted}/>)
                })}
            </ul>
        </div>:<div className="empty-list-wrap">
        <div className="center-vertical-horizontal">
             <h3>Looks like there are no recent Orders</h3>
        </div>
    </div>}
    </div>)
}