import { useContext } from "react";
import { NavLink } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";
import { auth } from "../../utils/firebaseSetup";
import { useNavigate } from "react-router-dom";

function CustomNavBar() {
  const user = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">Sree Speedy Laundry - Admin</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <NavLink href="/all-orders" onClick={() => navigate("/all-orders")}>
              All Orders
            </NavLink>
            <NavLink href="/settings" onClick={() => navigate("/settings")}>
              Settings
            </NavLink>
          </Nav>
          <Nav className="justify-content-end">
            {user ? (
              <NavDropdown
                title={
                  user?.displayName
                    ? user.displayName
                    : user?.phoneNumber
                    ? user.phoneNumber.substring(3)
                    : user.email
                    ? user.email
                    : ""
                }
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={() => {
                    auth.signOut();
                    toast.success("Successfully Logged Out");
                  }}
                >
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <NavLink href="/sign-in" onClick={() => navigate("/sign-in")}>
                Sign In
              </NavLink>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavBar;
