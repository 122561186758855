import './OrderItem.scss';
import { Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/esm/Dropdown";
import DropdownButton from "react-bootstrap/esm/DropdownButton";
import { OrderData } from "../../models/OrderData.model"
import { getLast3NumericValue } from "../../utils/orderIdUtil";
import { deleteDoc, doc, DocumentReference } from 'firebase/firestore';
import { db } from '../../utils/firebaseSetup';
import { toast } from 'react-toastify';

type OrderItemProps={
    order:OrderData,
    index:number,
    options:Intl.DateTimeFormatOptions,
    changeStatus:Function,
    statusList:string[],
    onDelete:Function
}



export default function OrderItem(props:OrderItemProps){
    const { order, index, options, changeStatus, statusList, onDelete} = props;

    function deleteOrder(){
        let humanOrderId = `${order.timeStamp.seconds.toString().substring(0, 6)}-${order.timeStamp.seconds.toString().substring(6)}-${getLast3NumericValue(order.orderId)}`;
        if(!window.confirm(`Are you sure want to delete order:${humanOrderId} ?`)){
            return;
        }
        let docRef:DocumentReference = doc(db, 'orders', `${order.orderId}`);
        deleteDoc(docRef).then((val)=>{
            toast.success(`Order:${humanOrderId} deleted Successfully`);
            onDelete(index);
        }, (error)=>toast.error(error.toString()));
    }
    
    return (<li key={order.orderId+order.status} className="orders-list-item">
        <div className="order-item-wrap">
            <div className="order-item">
                <div className="header-row">
                    <div className="order-placed">
                        <div className="heading">
                            <p>ORDER PLACED</p>
                        </div>
                        <div className="date" title={order.timeStamp.toDate().toLocaleString('en-US', options)}>
                            {order.timeStamp.toDate().toLocaleString('en-US', options)}
                        </div>
                    </div>
                    <div className="order-id-status-wrap">
                        <div className="order-id-wrap">
                            <div className="heading" title={order.orderId}>
                                    <p># <span className="order-id">{order.timeStamp.seconds.toString().substring(0, 6)+'-'+order.timeStamp.seconds.toString().substring(6)}-{getLast3NumericValue(order.orderId)}</span></p>
                            </div>
                        </div>
                        <div className="order-status-wrap">
                            <div className="heading" title={order.status}>
                                    <p>ORDER STATUS:</p>
                                    <DropdownButton id="dropdown-basic-button" className="order-status" title={order.status}>
                                        {statusList.map((val:string)=>{
                                            return <Dropdown.Item key={val} onClick={()=>changeStatus(val, order.orderId, index)}>{val}</Dropdown.Item>
                                        })}
                                    </DropdownButton>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="name-phone-numbers-wrap">
                        <div className="name-wrap" title={order.name}>
                            <h6>Name:</h6><p>{order.name}</p>
                        </div>
                        <h6>Phone: </h6>
                        {order.pickupPhoneNumber===order.userPhoneNumber.substring(3)?
                        <div className="phone-number" title={order.pickupPhoneNumber}>
                            <p><span>{order.pickupPhoneNumber}</span></p>
                        </div>:
                        <><div className="phone-number" title={order.pickupPhoneNumber}>
                            <p>Preferred: <span>{order.pickupPhoneNumber}</span></p>
                        </div>
                        <div className="phone-number" title={order.userPhoneNumber}>
                            <p>Registered: <span>{order.userPhoneNumber.substring(3)}</span></p>
                        </div></>}
                    </div>
                    <div className="address-wrap" title={order.address}>
                        <div className="address-heading">
                            <h6>Address:</h6>
                        </div>
                        <div className="address-content">
                            <p>{order.address}</p>
                        </div>
                    </div>
                </div>
                <Button onClick={deleteOrder} className="delete-order-btn" variant="outline-danger">X</Button>
            </div>
        </div>
    </li>)
}