import './OrderStatusEditList.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { collection, deleteDoc, doc, DocumentReference, getDocs, orderBy, query, QuerySnapshot, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../../utils/firebaseSetup';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';

function OrderStatusEditList({  }: {  }) {


    const [statusList, setStatusList]  = useState<string[]>();

    const [newStatus, setNewStatus] = useState('');
    const user = useContext(AuthContext);

    useEffect(()=>{
        refreshList();
    }, [])
    
    function refreshList(){
        getDocs(query(collection(db, 'statusList'), orderBy('timeStamp', 'asc'))).then((q:QuerySnapshot)=>{
            let statuses:string[] = [];
            q.forEach(d=>statuses.push(d.id));
            setStatusList(statuses);
        })
    }
   

    const handleAddButton = async () => {
        if(!user){
            return;
        }
        if (!newStatus.replace(/\s/g, '').length) {
            console.log('string only contains whitespace (ie. spaces, tabs or line breaks)');
            return;
        }
        else{
            setDoc(doc(db, 'statusList', newStatus), {
                createdBy:user.phoneNumber,
                timeStamp:serverTimestamp()
            }).then((val)=>{
                refreshList();
                toast.success(`status "${newStatus}" added Successfully`);
                setNewStatus('');
            }, (error)=>toast.error(error.toString()));     
        }
    };

    const onNewStatusChange = (e:any) =>{        
        setNewStatus(e.target.value);
    }

    function deleteTheDoc(statusName:string){
        if(!window.confirm(`Are you sure want to delete Status:${statusName} ?`)){
            return;
        }

        let docRef:DocumentReference = doc(db, 'statusList', statusName);
        deleteDoc(docRef).then((val)=>{
            refreshList();
            toast.success(`Status "${statusName}" deleted Successfully`);
            setNewStatus('');
        }, (error)=>toast.error(error.toString()));
    }


    return (
        <div className='status-list'>
            <div className='heading'>
                <p>Status List</p>
            </div>
        
            <div className='status-list-wrap'>
                <ul style={{listStyle:'none'}}>
                    {statusList?null:'Loading...'}
                    {statusList?.map((status: string, index:number) => (
                        <li key={index}><div className='status-name-wrap'>
                                <span className='status-name'>{status}</span>
                            </div>
                            <Button onClick={()=>deleteTheDoc(status)} className="delete-btn" variant="outline-danger">X</Button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className='add-new-wrap'>
                <div className='status-input-wrap'>
                    <input type="text" name="phonenumber" className="form-control" 
                    placeholder="Enter status name" id="name" required data-validation-required-message="Please enter your Phone numnber."
                        value={newStatus} 
                        pattern="[0-9]{10}"
                        onChange={onNewStatusChange}
                    />
                </div>
                <div className='add-btn-wrap'>
                        <Button onClick={handleAddButton}>Add</Button>
                </div>
            </div>
        </div>
    );
}

export default OrderStatusEditList;
