import React, { useContext } from 'react';
import logo from './logo.svg';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomNavBar from './components/CustomNavBar/CustomNavBar';

import { BrowserRouter, NavLink, Route, Routes } from 'react-router-dom';
import SignInPage from './pages/SignIn/SignInPage';
import OrdersPage from './pages/Orders/OrdersPage';
import { AuthContext } from './context/AuthContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserOrdersPage from './pages/UserOrders/UserOrdersPage';
import SettingsPage from './pages/Settings/SettingsPage';

function App() {

  const user = useContext(AuthContext);
  
  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className='router-wrap'>
        <BrowserRouter>
          <div className='top-nav'>
            <CustomNavBar />
          </div>
          <div className='pages-wrap'>
            <Routes>
              <Route index element={user?<OrdersPage />:<SignInPage />} />
              <Route path="all-orders" element={user?<OrdersPage />:<SignInPage />} />
              <Route path="settings" element={user?<SettingsPage />:<SignInPage />} />
              <Route path="user-orders/:userId" element={user?<UserOrdersPage />:<SignInPage />} />
              <Route path="sign-in" element={<SignInPage />} />
            </Routes>
          </div>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
