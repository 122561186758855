import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth }from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB-L-GOXSkues0ATaXUIEE_-ewY_NZHr3k",
    authDomain: "speedy-laundry.firebaseapp.com",
    projectId: "speedy-laundry",
    storageBucket: "speedy-laundry.appspot.com",
    messagingSenderId: "1030330863345",
    appId: "1:1030330863345:web:17ea9cf0abfcda8be1e350",
    measurementId: "G-4V3S7YJ767"
  };  

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const gAuthProvider = new GoogleAuthProvider();

gAuthProvider.setCustomParameters({ prompt: 'select_account' });
